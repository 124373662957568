.dropdown-menu {
  gap: .25rem;
  padding: .25rem;
}

.dropdown-menu.show {
  display: grid;
}

.dropdown-item {
  border-radius: .25rem;
}

.btn-sm ~ .dropdown-menu {
  font-size: .875rem;
}
