
$min-contrast-ratio:  3;
$border-color:        $gray-200;

$badge-border-radius: $border-radius-sm;

$box-shadow:                  0 .5rem 1rem rgba($black, .1);
$box-shadow-sm:               0 .125rem .25rem rgba($black, .05);
$box-shadow-lg:               0 1rem 3rem rgba($black, .15);

$nav-tabs-border-color:               rgba($black, .075);
$nav-tabs-link-active-border-color:   $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-link-active-bg;

$card-border-radius:          $border-radius-lg;
$card-border-color:           rgba($black, .075);
$card-inner-border-radius:    subtract($card-border-radius, $card-border-width);
$card-cap-bg:                 rgba($black, .0375);

$table-active-bg-factor:        .05;
$table-active-bg:               rgba($black, $table-active-bg-factor);
$table-hover-bg-factor:         .025;
$table-hover-bg:                rgba($black, $table-hover-bg-factor);
$table-border-color:            $border-color;
$table-group-separator-color:   $table-border-color;

$form-label-margin-bottom:          .25rem;
$form-label-font-size:              .875rem;
$form-label-color:                  $gray-600;
$form-label-font-weight:            500;
$form-feedback-tooltip-font-size:   .75rem;
$form-feedback-margin-top:          .125rem;
$form-feedback-font-size:           .75rem;

$form-switch-color:                 $white;
$form-switch-bg-image:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");

$input-border-color:              $gray-300;
$input-group-addon-border-color:  $input-border-color;
$form-select-border-color:        $gray-300;

$form-check-input-border:         1px solid $gray-300;

$modal-backdrop-opacity:      .25;
$modal-header-border-color:   $border-color;
$modal-content-border-color:  rgba($black, .05);
$offcanvas-border-color:      $modal-content-border-color;

$offcanvas-horizontal-width:  500px;

$dropdown-box-shadow:     $box-shadow-sm;
$dropdown-border-color:   rgba($black, .0875);
$dropdown-divider-bg:     $dropdown-border-color;

$accordion-border-color:    rgba($black, .0875);

$popover-border-color:      rgba($black, .125);

$toast-border-color:        rgba($black, .0875);
$toast-padding-x:           1rem;
$toast-padding-y:           .75rem;

$badge-font-weight:           $font-weight-semibold;

$list-group-border-color:     rgba($black, .0875);

$hr-opacity: .125;

$nav-tabs-link-hover-border-color:  transparent;
